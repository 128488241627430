import { Button, Layer } from 'grommet';
import { cloneElement, useContext, useEffect, useState } from 'react';
import { RunningSessionContext, WorkspaceContext } from '../../../contexts';
import { addProject } from '../../../database/projects';
import { startSession, stopSession } from '../../../database/sessions';
import { addTask } from '../../../database/tasks';
import { Close } from 'grommet-icons';

export default function CreateTimer({ initialTaskName, button, projectID }) {
  const { projects, tasks } = useContext(WorkspaceContext);
  const runningSession = useContext(RunningSessionContext);

  const filteredProject = projects.find(p => p.id === projectID);
  const activeProjects = projects.filter(p => !p.isArchived);

  const [taskName, setTaskName] = useState(initialTaskName);
  const [projectName, setProjectName] = useState('');
  const [existingProject, setExistingProject] = useState(null);
  const [existingTask, setExistingTask] = useState(null);
  const [isDialogVisible, toggleDialog] = useState(false);
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    if (filteredProject) {
      setProjectName(filteredProject.name);
      setExistingProject(filteredProject);
    }
  }, [filteredProject]);

  useEffect(() => {
    // Check if project or task already exists.
    let existingProject;
    let existingTask;
    existingProject = activeProjects.find(p => p.name === projectName);
    if (existingProject) {
      const projectTasks = tasks.filter(t => t.project === existingProject.id);
      existingTask = projectTasks.find(t => t.name === taskName);
    }
    setExistingProject(existingProject);
    setExistingTask(existingTask);
  }, [activeProjects, tasks, taskName, projectName]);

  const close = () => toggleDialog(false);

  const onSubmit = async e => {
    // Don't reload browser window:
    e.preventDefault();
    // Create project if necessary:
    let projectID;
    if (!existingProject) {
      const projectRef = await addProject(projectName);
      projectID = projectRef.id;
    } else {
      projectID = existingProject.id;
    }
    // Create task if necessary:
    if (!existingTask) {
      await addTask(projectID, taskName);
    }
    // If task and project already exist, start a session:
    if (existingTask && existingProject) {
      // If another session is running, stop it:
      if (runningSession) {
        await stopSession(runningSession);
      }
      await startSession({ projectID, taskID: existingTask.id });
    }
    // Close the dialog to make it feel snappy:
    close();
  };

  return (
    <>
      {cloneElement(button, { onClick: () => toggleDialog(true) })}
      {isDialogVisible && (
        <Layer onClickOutside={close} onEsc={close}>
          <div className="bg-white p-8 rounded-lg space-y-4">
            <button onClick={close}>
              <Close />
            </button>
            <form
              onSubmit={onSubmit}
              className="flex flex-col md:flex-row gap-2"
            >
              <input
                autoFocus
                list="projects"
                placeholder="Project Name"
                className="text-xl bg-gray-50 h-20 p-5 outline-none"
                value={projectName}
                onChange={e => setProjectName(e.target.value)}
              />
              <datalist id="projects">
                {activeProjects
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ id, name }) => (
                    <option key={id} value={name} dataValue={id} />
                  ))}
              </datalist>
              <input
                list="tasks"
                placeholder="Task Name"
                className="text-xl bg-gray-50 h-20 p-5 outline-none"
                value={taskName}
                onChange={e => setTaskName(e.target.value)}
              />
              <datalist id="tasks">
                {tasks
                  .filter(t => t.project === existingProject?.id)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ id, name }) => (
                    <option key={id} value={name} dataValue={id} />
                  ))}
              </datalist>
              <Button
                primary
                type="submit"
                size="large"
                className="whitespace-nowrap"
                disabled={!taskName || !projectName}
                label={
                  existingTask && existingProject
                    ? 'Start task'
                    : taskName &&
                      !existingTask &&
                      projectName &&
                      !existingProject
                    ? 'Create task and project'
                    : 'Create task'
                }
              />
            </form>
            {existingTask && existingProject && (
              <p className="text-gray-500 mt-4 text-center">
                This task already exists.
              </p>
            )}
          </div>
        </Layer>
      )}
    </>
  );
}
