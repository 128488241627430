import { Menu } from 'grommet';
import { useEffect, useState } from 'react';
import { MdNotifications } from 'react-icons/md';
import { getLongSessions } from '../database/sessions';

function Notifications() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getLongSessions().then(sessions => {
      if (sessions.length > 0) {
        setItems(i => [
          ...i,
          {
            label: `You have ${sessions.length} session(s) over 8 hours. Please tell us if you forgot to stop the timer.`,
            onClick: () => {},
          },
        ]);
      }
    });
  }, []);

  if (items.length === 0) {
    return null;
  }

  return <Menu label={<MdNotifications />} items={items} />;
}

export default Notifications;
