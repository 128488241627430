import PlayPauseButton from './PlayPauseButton';
import { startSession, stopSession } from '../database/sessions';
import useRunningDuration from '../hooks/useRunningDuration';
import { useContext } from 'react';
import { WorkspaceContext } from '../contexts';

function SessionToggler({ runningSession, lastTaskID, lastProjectID }) {
  const duration = useRunningDuration(runningSession);
  const { projects, tasks } = useContext(WorkspaceContext);

  const task = tasks?.find(p => p.id === (runningSession?.task || lastTaskID));
  const project = projects?.find(
    p => p.id === (runningSession?.project || lastProjectID)
  );

  if (!runningSession && !lastTaskID) {
    return null;
  }

  return (
    <button
      onClick={() =>
        runningSession
          ? stopSession(runningSession)
          : startSession({
              projectID: lastProjectID,
              taskID: lastTaskID,
            })
      }
      className="group flex dark:border-gray-700 dark:text-white p-3 items-center justify-between group-hover:text-orange border-l border-r space-x-4"
    >
      <div className="text-left">
        <p className="text-xs">{project?.name}</p>
        <p className="font-bold">{task?.name}</p>
        <div className="flex items-center text-xs">
          {runningSession && (
            <div className="bg-green opacity-100 rounded-full h-2 w-2 mr-1 animate-pulse" />
          )}
          <span className="opacity-50">
            {runningSession ? duration : 'Last task'}
          </span>
        </div>
      </div>
      <PlayPauseButton runningSession={runningSession} />
    </button>
  );
}

export default SessionToggler;
