import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

export default function useRunningDuration(session) {
  const [duration, setDuration] = useState();

  useEffect(() => {
    if (session && session?.started) {
      const id = setInterval(() => {
        setDuration(
          intervalToDuration({
            start: session.started,
            end: new Date(),
          })
        );
      }, 1000);
      return () => clearInterval(id);
    } else {
      setDuration();
    }
  }, [session]);

  return `${duration?.hours || '0'}:${
    duration?.minutes
      ? duration.minutes < 10
        ? `0${duration.minutes}`
        : duration.minutes
      : '00'
  }`;
}
