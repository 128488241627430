import { Link } from 'react-router-dom';
import {
  MdFormatListBulleted,
  MdHistory,
  MdPlayArrow,
  MdTimeline,
} from 'react-icons/md';
import { useLocation } from 'react-router';
import classNames from 'classnames';

function Tabs() {
  const location = useLocation();

  const links = [
    {
      to: '/track',
      Icon: MdPlayArrow,
      label: 'Timers',
    },
    {
      to: '/calendar',
      Icon: MdHistory,
      label: 'History',
    },
    {
      to: '/projects',
      Icon: MdFormatListBulleted,
      label: 'Projects',
    },
    {
      to: '/analytics',
      Icon: MdTimeline,
      label: 'Reports',
    },
    // {
    //   to: '/account',
    //   Icon: MdAccountCircle,
    //   label: 'Account',
    // },
  ];

  return (
    <div className="flex h-full">
      {links.map(({ to, Icon, label }) => (
        <Link to={to} key={to}>
          <div
            className={classNames(
              'flex group items-center px-4 py-2 w-full h-full',
              {
                'bg-gray-50 dark:bg-gray-800 border-b border-white':
                  location.pathname.includes(to),
              }
            )}
          >
            {Icon && (
              <Icon
                fontSize="2em"
                className={classNames(
                  'dark:text-white p-1 group-hover:opacity-100 transition-opacity',
                  {
                    'opacity-70': !location.pathname.includes(to),
                  }
                )}
              />
            )}
            <p
              className={classNames(
                'ml-1 font-medium text-lg dark:text-white group-hover:opacity-100 hidden sm:block',
                {
                  'opacity-50': !location.pathname.includes(to),
                }
              )}
            >
              {label}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Tabs;
