import firebase from 'firebase/app';

export async function updateLastUserActivity({ projectID, taskID }) {
  const db = firebase.firestore();
  const batch = db.batch();
  const now = new Date();
  const lastActivityData = {
    [`lastUserActivity.${firebase.auth().currentUser.uid}`]: now,
  };
  const projectRef = db.collection('projects').doc(projectID);
  batch.update(projectRef, lastActivityData);
  if (taskID) {
    const taskRef = projectRef.collection('tasks').doc(taskID);
    batch.update(taskRef, lastActivityData);
  }
  return batch.commit();
}
