import { useContext, useEffect, useMemo, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import Timer from '../pages/app/tracking/Timer';
import { Layer } from 'grommet';
import tinykeys from 'tinykeys';
import fuzzy from 'fuzzy';
import CreateTimer from '../pages/app/tracking/CreateTimer';
import Button from './Button';
import { WorkspaceContext } from '../contexts';

export default function TimerSearch() {
  const { activeProjects, activeTasks } = useContext(WorkspaceContext);

  const [search, setSearch] = useState('');
  const [showLayer, toggleLayer] = useState(false);

  useEffect(() => {
    let unsubscribe = tinykeys(window, {
      // Command + K:
      '$mod+K': e => {
        e.preventDefault();
        toggleLayer(v => !v);
        setSearch('');
      },
      arrowDown: e => {
        // TODO: Implement arrow key focus change
      },
    });
    return () => {
      unsubscribe();
    };
  });

  const tasksWithProjectName = useMemo(
    () =>
      activeTasks?.map(task => {
        const project = activeProjects.find(p => p.id === task.project);
        return {
          ...task,
          projectName: project?.name,
          tags: project?.tags,
        };
      }) || [],
    [activeTasks, activeProjects]
  );

  const results = useMemo(() => {
    var options = {
      pre: '<strong>',
      post: '</strong>',
      extract: el =>
        `${el.tags ? el.tags.join(' ') : ''} ${el.projectName} ${el.name}`,
    };
    var results = fuzzy.filter(search, tasksWithProjectName, options);
    return results;
  }, [search, tasksWithProjectName]);

  const close = () => {
    toggleLayer(false);
    setSearch('');
  };

  return (
    <div className="py-4 flex space-x-2 min-w-xl">
      <button
        onClick={() => toggleLayer(true)}
        className="flex items-center rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-800 dark:text-white p-4 outline-none border mr-4 w-60"
        placeholder="Search Timer"
      >
        <MdSearch />
        <span className="ml-2">Search Timer</span>
        <span className="ml-auto pl-3 flex-none text-xs font-semibold">⌘K</span>
      </button>
      {showLayer && (
        <Layer
          className="h-3/4 max-w-lg flex flex-col gap-3 p-3"
          onEsc={close}
          onClickOutside={close}
        >
          <input
            autoFocus
            type="search"
            value={search}
            className="text-xl rounded-md bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-800 dark:text-white p-4 outline-none"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search Timer"
            list="ice-cream-flavors"
          />
          <div className="h-full overflow-auto flex flex-col space-y-1">
            {results.length ? (
              results.map(({ original }) => (
                <div key={original.id}>
                  <Timer
                    task={original}
                    project={activeProjects.find(
                      p => p.id === original.project
                    )}
                  />
                </div>
              ))
            ) : (
              <p className="text-center">No timers found.</p>
            )}
            {!search && (
              <div className="flex justify-center items-center opacity-50">
                Type to find a timer.
              </div>
            )}
          </div>
          <CreateTimer
            button={<Button label="Create new timer" className="text-xl" />}
          />
        </Layer>
      )}
    </div>
  );
}
