import firebase from 'firebase/app';
import { makeJsDates } from '../utils/firebase';
import { updateLastUserActivity } from './shared';

class Task {
  // Todo: add delete field to tasks
  constructor(id, data) {
    this.id = id;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }

  toString() {
    return this.name;
  }
}

// Firestore data converter, see: https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
const taskConverter = {
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options);
    data = makeJsDates(data);
    return new Task(snapshot.id, data);
  },
};

export function listenToChunkedProjectTasks(
  projectIDs,
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  return db
    .collectionGroup('tasks')
    .where('project', 'in', projectIDs)
    .withConverter(taskConverter)
    .onSnapshot(snapshot => {
      const tasks = [];
      snapshot.forEach(doc => {
        const task = doc.data();
        if (!task.isArchived) {
          tasks.push(task);
        }
      });
      onSnapshot(tasks);
    }, onError);
}

export function listenToProjectTasks(
  projectID,
  onSnapshot,
  onError = e => console.error(e)
) {
  return firebase
    .firestore()
    .collection('projects')
    .doc(projectID)
    .collection('tasks')
    .withConverter(taskConverter)
    .onSnapshot(snapshot => {
      const tasks = [];
      snapshot.forEach(doc => {
        const task = doc.data();
        if (!task.isArchived) {
          tasks.push(task);
        }
      });
      onSnapshot(tasks);
    }, onError);
}

export async function addTask(projectID, name) {
  const db = firebase.firestore();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  const userID = firebase.auth().currentUser.uid;
  await updateLastUserActivity({ projectID });
  return db.collection(`projects/${projectID}/tasks`).add({
    name: name,
    project: projectID,
    lastUserActivity: {
      [userID]: timestamp,
    },
    created: timestamp,
    createdBy: userID,
    modified: timestamp,
    modifiedBy: userID,
  });
}

export function updateTask(task, data) {
  const now = new Date();
  const db = firebase.firestore();
  const projectRef = db.doc(`projects/${task.project}/tasks/${task.id}`);
  return projectRef.update({
    ...data,
    modified: now,
  });
}

export async function deleteTask(task) {
  const db = firebase.firestore();
  const taskRef = db.doc(`projects/${task.project}/tasks/${task.id}`);
  return taskRef.delete();
}

export async function deleteTaskAndSessions(task) {
  const db = firebase.firestore();
  const batch = db.batch();
  const taskRef = db.doc(`projects/${task.project}/tasks/${task.id}`);
  // Get all session refs of this task:
  const taskSessionRefs = await taskRef
    .collection('sessions')
    .get()
    .then(snapshot => snapshot.docs.map(doc => doc.ref));
  // Delete the sessions:
  for (const sessionRef of taskSessionRefs) {
    batch.delete(sessionRef);
  }
  // Delete this task:
  batch.delete(taskRef);
  return batch.commit();
}
