import { Box } from 'grommet';
import Loader from './Loader';

function FullScreenLoader() {
  return (
    <Box
      background={{ light: 'white', dark: 'black' }}
      align="center"
      justify="center"
      height="100vh"
      width="100vw"
      direction="row"
    >
      <Loader />
    </Box>
  );
}

export default FullScreenLoader;
