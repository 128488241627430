import WhiteLogo from '../assets/logo-geometric-white.png';
import BlackLogo from '../assets/logo-geometric-black.png';

function Logo() {
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return (
    <img
      src={darkMode ? WhiteLogo : BlackLogo}
      className="h-9 m-4"
      alt="Just Press Work"
    />
  );
}

export default Logo;
