import { useContext, useState } from 'react';
import { startSession, stopSession } from '../../../database/sessions';
import { getProjectColor } from '../../../utils/colors';
import { RunningSessionContext, TrackingContext } from '../../../contexts';
import classNames from 'classnames';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import useRunningDuration from '../../../hooks/useRunningDuration';

export default function Timer({ project, task, hideProject }) {
  const runningSession = useContext(RunningSessionContext);
  const duration = useRunningDuration(runningSession);

  const { setProjectFilter } = useContext(TrackingContext);

  const [isLoading, setLoading] = useState(false);

  const runningSessionForThisTask =
    runningSession?.task === task.id ? runningSession : null;

  if (!project) {
    return null;
  }

  return (
    <button
      key={project.id + task.id}
      className={classNames(
        'group sm:rounded border bg-white flex w-full items-center'
      )}
      disabled={isLoading}
      style={{
        backgroundColor: `${getProjectColor(project.color)}30`,
        color: getProjectColor(project.color),
      }}
      onClick={
        runningSessionForThisTask
          ? () => stopSession(runningSessionForThisTask)
          : async () => {
              setLoading(true);
              // If another session is running, stop it:
              if (runningSession) {
                await stopSession(runningSession);
              }
              await startSession({
                projectID: project.id,
                taskID: task.id,
              });
              setLoading(false);
            }
      }
    >
      <div className="p-3 flex items-center w-full text-left space-x-3">
        <div
          className={classNames(
            'rounded-full p-2 bg-white group-hover:bg-opacity-100 dark:bg-gray-800 border text-black dark:text-white'
          )}
        >
          {runningSessionForThisTask ? (
            <MdPause className="text-2xl group-hover:text-brand" />
          ) : (
            <MdPlayArrow className="text-2xl group-hover:opacity-100 group-hover:text-brand" />
          )}
        </div>
        <div className="flex-1 flex items-baseline gap-2">
          <span className="font-semibold text-xl sm:text-xl">{task.name}</span>
          {runningSessionForThisTask && (
            <span className="text-xl sm:text-xl animate-pulse">{duration}</span>
          )}
        </div>
        {!hideProject && (
          <button
            onClick={e => {
              e.stopPropagation();
              setProjectFilter(project.id);
            }}
            style={{
              backgroundColor: getProjectColor(project.color),
            }}
            className="p-1 px-2 rounded hover:bg-black/10 text-white bg-white bg-opacity-10 font-medium text-sm"
          >
            {project.name}
          </button>
        )}
      </div>
    </button>
  );
}
