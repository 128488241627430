export const makeJsDates = firebaseData => {
  const data = { ...firebaseData };
  for (const key in firebaseData) {
    if (firebaseData.hasOwnProperty(key)) {
      if (firebaseData[key].toDate) {
        data[key] = firebaseData[key].toDate();
      }
    }
  }
  return data;
};
