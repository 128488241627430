import BlackLogo from '../assets/logo-geometric-black.png';
import { useState, useEffect, useContext } from 'react';
import { Avatar } from 'grommet';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import AnchorLink from './AnchorLink';
import { RunningSessionContext, UserContext } from '../contexts';
import Notifications from './Notifications';
import Tabs from './Tabs';
import Logo from './Logo';
import SessionToggler from './SessionToggler';
import { Link } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md';
import Button from './Button';
import TimerSearch from './TimerSearch';

function Navigation({ lastProjectID, lastTaskID }) {
  const history = useHistory();
  // Hide the header on small devices in landscape mode:
  const [deviceOrientation, setDeviceOrientation] = useState(
    window.orientation
  );
  const runningSession = useContext(RunningSessionContext);

  useEffect(() => {
    window.addEventListener('', () => setDeviceOrientation(window.orientation));
  }, []);

  if ((window.innerHeight < 600) & (deviceOrientation !== 0)) {
    return null;
  }

  const { currentUser } = firebase.auth();
  const isSignedIn = !!currentUser;

  const links = [
    {
      label: 'Sign in',
      path: '/sign-in',
    },
  ];

  if (isSignedIn) {
    return (
      <UserContext.Consumer>
        {user => (
          <nav className="border-b h-full dark:bg-gray-900 dark:border-gray-800 bg-gray-50 overflow-x-auto">
            <div className="flex flex-row items-center">
              <Link to="/" className="h-full flex items-center">
                <Logo />
              </Link>
              <div className="flex flex-col flex-grow space-x-2">
                <Tabs />
                <div>
                  {firebase.auth().currentUser.isAnonymous ? (
                    <Button
                      onClick={() => history.push('/register')}
                      primary
                      label="Save your data"
                    />
                  ) : (
                    <>
                      <Notifications />
                      {
                        // <Menu
                        //   label={
                        //     user ? (
                        //       <Avatar background="light-2" src={user.image}>
                        //         {user.firstName.slice(0, 1) +
                        //           user.lastName.slice(0, 1)}
                        //       </Avatar>
                        //     ) : (
                        //       ''
                        //     )
                        //   }
                        //   items={[
                        //     {
                        //       label: 'Edit profile',
                        //       onClick: () => history.push('/account/profile'),
                        //     },
                        //     {
                        //       label: 'Change billing',
                        //       onClick: () => history.push('/account/billing'),
                        //     },
                        //     {
                        //       label: 'Invite friends',
                        //       onClick: () => history.push('/invite'),
                        //     },
                        //     {
                        //       label: 'Manage team',
                        //       onClick: () => history.push('/team'),
                        //     },
                        //     {
                        //       label: 'Sign out',
                        //       onClick: () => firebase.auth().signOut(),
                        //     },
                        //   ]}
                        // />
                      }
                    </>
                  )}
                </div>
              </div>
              <TimerSearch />
              <SessionToggler
                runningSession={runningSession}
                lastTaskID={lastTaskID}
                lastProjectID={lastProjectID}
              />
              <Link to="/account" className="p-4">
                {user?.image ? (
                  <Avatar src={user.image} />
                ) : (
                  <MdAccountCircle fontSize={'1.5em'} />
                )}
              </Link>
            </div>
          </nav>
        )}
      </UserContext.Consumer>
    );
  }

  return (
    <nav className="flex justify-between p-4">
      <img src={BlackLogo} className="h-9" alt="Just Press Work" />
      <div className="flex space-x-4 items-center">
        {links.map(link => (
          <AnchorLink
            key={link.path}
            to={link.path}
            color="dark-1"
            label={link.label}
          />
        ))}
        <Button primary to="/register" label="Create free timesheet" />
      </div>
    </nav>
  );
}

export default Navigation;
