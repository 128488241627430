import { Box } from 'grommet';
import { withRouter } from 'react-router-dom';
import Navigation from '../components/Navigation';

function BaseLayout(props) {
  return (
    <Box
      height={{ max: '100%', min: '100%' }}
      background={props.background || 'light-1'}
    >
      <Navigation workspace={props.workspace} />
      <Box height={{ max: '100%' }} pad={props.pad || 'medium'}>
        {props.children}
      </Box>
    </Box>
  );
}

export default withRouter(BaseLayout);
