import firebase from 'firebase/app';
import { makeJsDates } from '../utils/firebase';

class Project {
  constructor(id, data) {
    this.id = id;
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }

  toString() {
    return this.name + ', ' + this.id + ', ' + this.created;
  }
}

// Firestore data converter, see: https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
const projectConverter = {
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options);
    data = makeJsDates(data);
    return new Project(snapshot.id, data);
  },
};

export function listenToUserProjects(
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  return db
    .collection('projects')
    .where('members', 'array-contains', firebase.auth().currentUser.uid)
    .orderBy('created', 'asc')
    .withConverter(projectConverter)
    .onSnapshot(snapshot => {
      onSnapshot(snapshot.docs.map(doc => doc.data()));
    }, onError);
}

export function addProject(projectName) {
  const userID = firebase.auth().currentUser.uid;
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  return firebase
    .firestore()
    .collection('projects')
    .add({
      name: projectName,
      admins: [userID],
      members: [userID],
      created: timestamp,
      modified: timestamp,
      lastUserActivity: {
        [userID]: timestamp,
      },
      createdBy: userID,
      isArchived: false,
    });
}

export function updateProject(project, data) {
  const now = new Date();
  const db = firebase.firestore();
  const projectRef = db.doc(`projects/${project.id}`);
  return projectRef.update({
    ...data,
    modified: now,
    updatedBy: firebase.auth().currentUser.uid,
  });
}

export function listenToProject(projectID, onSnapshot) {
  const db = firebase.firestore();
  const projectRef = db.doc(`projects/${projectID}`);
  return projectRef
    .withConverter(projectConverter)
    .onSnapshot(snapshot => onSnapshot(snapshot.data()));
}
