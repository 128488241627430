import { createContext } from 'react';

export const UserContext = createContext({});
export const WorkspacesContext = createContext([]);
export const WorkspaceContext = createContext({});
export const ThemeContext = createContext({
  theme: 'light',
  toggleTheme: () => {},
});
export const OnboardingContext = createContext({});
export const RunningSessionContext = createContext(null);
export const AnalyticsContext = createContext({
  filters: {
    interval: {},
    user: '',
    tag: '',
    project: '',
    task: '',
  },
  setFilters: () => {},
  sessions: [],
  breakdownBy: '',
  setBreakdownBy: () => {},
});
export const TrackingContext = createContext({
  projectFilter: '',
  setProjectFilter: () => {},
});
