import { Component } from 'react';
import BaseLayout from '../layouts/BaseLayout';
import { Heading, Button } from 'grommet';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error(error);
    // You can also log the error to an error reporting service
    // TODO: Add Sentry or so
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <BaseLayout>
          <Heading level="1">Something went wrong.</Heading>
          <div className="text-sm">{this.state.error?.stack}</div>
          <Button
            primary
            label="Take me home"
            onClick={() => this.props.history.push('/')}
          />
        </BaseLayout>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
