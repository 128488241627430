const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomColor = () =>
  `rgb(${getRandomInt(200, 225)},
  ${getRandomInt(200, 225)},
  ${getRandomInt(200, 225)})`;

export const getProjectColor = color => color || '#63666A';
