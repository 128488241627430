import { useEffect } from 'react';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import FullScreenLoader from '../../components/FullScreenLoader';
import { useLocation } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function JoinProject({ project }) {
  const query = useQuery();
  const history = useHistory();
  const { currentUser } = firebase.auth();
  const path = window.location.pathname + window.location.search;

  useEffect(() => {
    const joinProject = async () => {
      // If the user has no account, redirect to the registration page.
      if (!currentUser || currentUser.isAnonymous) {
        return history.push('/register', { next: path });
      }
      const projectID = query.get('id');
      if (!project) {
        // Join this project:
        const projectRef = firebase
          .firestore()
          .collection('projects')
          .doc(projectID);
        // Add this user to the project members:
        await projectRef.update({
          members: firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
        });
      }
      return history.push(`/projects/${projectID}`);
    };
    joinProject();
  }, [history, query, currentUser, project, path]);

  return <FullScreenLoader />;
}
