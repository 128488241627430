import { PlayFill } from 'grommet-icons';

function Loader() {
  return (
    <div className="rotate">
      <PlayFill color="black" size="medium" />
    </div>
  );
}

export default Loader;
