import firebase from 'firebase/app';
import { makeJsDates } from '../utils/firebase';
import dayjs from 'dayjs';
import { endOfDay, startOfDay } from 'date-fns';
import { updateLastUserActivity } from './shared';

// Firestore data converter, see: https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
export const sessionConverter = {
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options);
    data = makeJsDates(data);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

export function getLongSessions() {
  const db = firebase.firestore();
  const maxDuration = 1000 * 60 * 60 * 8; // 8 hours
  let query = db
    .collectionGroup('sessions')
    .where('user', '==', firebase.auth().currentUser.uid)
    .where('duration', '>=', maxDuration);
  return query
    .orderBy('duration', 'desc')
    .withConverter(sessionConverter)
    .get();
}

export function listenToUserSessionsInInterval(
  interval,
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  const rangeStart = dayjs(interval.startDate).startOf('day').toDate();
  const rangeEnd = dayjs(interval.endDate).endOf('day').toDate();
  let query = db
    .collectionGroup('sessions')
    .where('user', '==', firebase.auth().currentUser.uid)
    .where('started', '>', rangeStart)
    .where('started', '<', rangeEnd);
  return query
    .orderBy('started', 'desc')
    .withConverter(sessionConverter)
    .onSnapshot(snapshot => {
      const sessions = [];
      snapshot.forEach(doc => {
        sessions.push(doc.data());
      });
      onSnapshot(sessions);
    }, onError);
}

export function listenToUserSessions(
  startDate,
  endDate,
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  return db
    .collectionGroup('sessions')
    .where('user', '==', firebase.auth().currentUser.uid)
    .where('started', '>', startOfDay(startDate))
    .where('started', '<', endOfDay(endDate))
    .withConverter(sessionConverter)
    .onSnapshot(snapshot => {
      const sessions = [];
      snapshot.forEach(doc => {
        sessions.push(doc.data());
      });
      onSnapshot(sessions);
    }, onError);
}

/**
 * This should only return one session.
 */
export function listenToRunningUserSessions(
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  return db
    .collectionGroup('sessions')
    .where('user', '==', firebase.auth().currentUser.uid)
    .where('stopped', '==', false)
    .withConverter(sessionConverter)
    .onSnapshot(snapshot => {
      const sessions = [];
      snapshot.forEach(doc => {
        sessions.push(doc.data());
      });
      onSnapshot(sessions);
    }, onError);
}

export async function startSession({ projectID, taskID }) {
  const db = firebase.firestore();
  await updateLastUserActivity({ projectID, taskID });
  return db
    .collection('projects')
    .doc(projectID)
    .collection('tasks')
    .doc(taskID)
    .collection('sessions')
    .add({
      started: new Date(),
      stopped: false,
      user: firebase.auth().currentUser.uid,
      project: projectID,
      task: taskID,
    });
}

function getDuration(start, end) {
  return end.valueOf() - start.valueOf();
}

export function stopSession(session) {
  const db = firebase.firestore();
  const sessionRef = db.doc(
    `projects/${session.project}/tasks/${session.task}/sessions/${session.id}`
  );
  const now = new Date();
  // Delete the session if it has been running less than a minute:
  const ranLessThanAMinute = dayjs(session.started)
    .add(1, 'minute')
    .isAfter(now);
  return ranLessThanAMinute
    ? sessionRef.delete()
    : sessionRef.update({
        stopped: now,
        duration: getDuration(session.started, now),
      });
}

export function addSession(projectID, taskID, started, stopped = false) {
  if (!dayjs(stopped).isAfter(started)) return; // Don't save corrupted sessions.
  const db = firebase.firestore();
  const sessionRef = db
    .collection(`projects/${projectID}/tasks/${taskID}/sessions`)
    .doc();
  return sessionRef.set({
    project: projectID,
    task: taskID,
    user: firebase.auth().currentUser.uid,
    started,
    stopped,
    duration: getDuration(started, stopped),
    addedManually: true,
  });
}

export function editSession(session, started, stopped) {
  if (stopped && !dayjs(stopped).isAfter(started)) return; // Don't save corrupted sessions.
  const db = firebase.firestore();
  const sessionRef = db.doc(
    `projects/${session.project}/tasks/${session.task}/sessions/${session.id}`
  );
  return sessionRef.update({
    started,
    stopped,
    edited: true,
    duration: getDuration(started, stopped),
  });
}

export function changeSession(session) {
  const db = firebase.firestore();
  const sessionRef = db.doc(
    `projects/${session.project}/tasks/${session.task}/sessions/${session.id}`
  );
  return sessionRef.update({
    ...session,
    edited: true,
    duration: getDuration(session.started, session.stopped),
  });
}

export function deleteSession(session) {
  const db = firebase.firestore();
  const sessionRef = db.doc(
    `projects/${session.project}/tasks/${session.task}/sessions/${session.id}`
  );
  return sessionRef.delete();
}
