import { Component, Suspense, lazy } from 'react';
import { Grommet, grommet, generate } from 'grommet';
import { BrowserRouter as Router } from 'react-router-dom';
import grommetTheme from './theme';
import { deepMerge } from 'grommet/utils';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import FullScreenLoader from './components/FullScreenLoader';
import { getUser } from './database/user';
import { ThemeContext, UserContext } from './contexts';
import ErrorBoundary from './components/ErrorBoundary';
import { Switch, Route } from 'react-router';
import JoinProject from './pages/site/JoinProject';

const SiteRoutes = lazy(() => import('./routes/SiteRoutes'));
const AppRoutes = lazy(() => import('./routes/AppRoutes'));
const Register = lazy(() => import('./pages/site/Register'));

const firebaseConfig = {
  apiKey: 'AIzaSyA48FwRWvj_BiDyXo0karQo8yX-6EjN3nw',
  authDomain: 'just-press-work.firebaseapp.com',
  databaseURL: 'https://just-press-work.firebaseio.com',
  projectId: 'just-press-work',
  storageBucket: 'just-press-work.appspot.com',
  messagingSenderId: '741593384560',
  appId: '1:741593384560:web:e9ccccaf165383712a16b5',
  measurementId: 'G-YVSL6G9SJ1',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      user: null,
      theme: 'light',
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        this.unsubscribeUser = getUser(user =>
          this.setState({
            user,
            isLoading: false,
          })
        );
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <ThemeContext.Provider
        value={{
          theme: this.state.theme,
          toggleTheme: () =>
            this.setState(prevState => ({
              theme: prevState.theme === 'light' ? 'dark' : 'light',
            })),
        }}
      >
        <UserContext.Provider value={this.state.user}>
          <Grommet
            themeMode={this.state.theme}
            theme={deepMerge(grommet, generate(20), grommetTheme)}
          >
            {isLoading ? (
              <FullScreenLoader />
            ) : (
              <Router>
                <Suspense fallback={<FullScreenLoader />}>
                  <ErrorBoundary>
                    <Switch>
                      {
                        // Include /register here to enable turning anonymous accounts into real accounts.
                        <Route path="/register">
                          <Register />
                        </Route>
                      }
                      <Route path="/join-project">
                        <JoinProject />
                      </Route>
                      <Route path="/">
                        {firebase.auth().currentUser ? (
                          <AppRoutes />
                        ) : (
                          <SiteRoutes />
                        )}
                      </Route>
                    </Switch>
                  </ErrorBoundary>
                </Suspense>
              </Router>
            )}
          </Grommet>
        </UserContext.Provider>
      </ThemeContext.Provider>
    );
  }
}

export default App;
