import classnames from 'classnames';
import { Link } from 'react-router-dom';

function Button({
  label,
  to,
  onClick,
  secondary,
  plain,
  icon,
  className,
  ...rest
}) {
  const props = {
    to,
    onClick,
    className: classnames([
      'font-medium flex justify-center items-center text-center px-4 py-2 rounded hover:shadow-md',
      {
        'bg-brand': !plain && !secondary,
        'bg-white dark:bg-gray-900': plain || secondary,
        'text-white': !plain && !secondary,
        'text-orange': plain,
        'text-brand border border-brand': secondary,
        'bg-brand bg-opacity-10': secondary,
      },
      className,
    ]),
    children: (
      <>
        {icon && <div className="mr-2">{icon}</div>}
        {label}
      </>
    ),
    ...rest,
  };
  return to ? <Link href={to} {...props} /> : <button {...props} />;
}

export default Button;
