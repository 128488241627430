import firebase from 'firebase/app';
import { makeJsDates } from '../utils/firebase';
import chunk from 'lodash/chunk';

// Firestore data converter, see: https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
const userConverter = {
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options);
    data = makeJsDates(data);
    return { id: snapshot.id, ...data };
  },
};

export function createUserProfile() {
  const db = firebase.firestore();
  const { uid } = firebase.auth().currentUser;
  const now = new Date();
  return db.collection('users').doc(uid).set({
    id: uid,
    created: now,
  });
}

export async function createAnonymousUser() {
  return firebase
    .auth()
    .signInAnonymously()
    .then(async () => {
      await createUserProfile();
      return firebase.auth().currentUser.uid;
    })
    .catch(e => console.error(e));
}

export async function getUserById(userId) {
  const db = firebase.firestore();
  try {
    return db
      .doc(`users/${userId}`)
      .withConverter(userConverter)
      .get()
      .then(snapshot => ({ id: snapshot.id, ...snapshot.data() }));
  } catch (e) {
    return console.error(e);
  }
}

export function getUser(onSnapshot, onError = e => console.error(e)) {
  const db = firebase.firestore();
  return db
    .doc(`users/${firebase.auth().currentUser.uid}`)
    .withConverter(userConverter)
    .onSnapshot(snapshot => {
      if (snapshot.exists) {
        onSnapshot(snapshot.data());
      } else {
        onSnapshot(null);
      }
    }, onError);
}

export function listenToUsersInArray(
  array,
  onSnapshot,
  onError = e => console.error(e)
) {
  const db = firebase.firestore();
  if (array.length === 0) {
    return onSnapshot([]);
  }

  const userChunks = chunk(array, 10);
  const unsubscribers = userChunks.map(chunk => {
    const query  = db
      .collection('users')
      .where('id', 'in', chunk)

    return query.onSnapshot(snapshot => {
      const users = [];
      snapshot.forEach(doc => {
        const user = makeJsDates(doc.data());
        users.push({
          id: doc.id,
          ...user,
        });
      });
      onSnapshot(users);
    }, onError);
  })
  return () => unsubscribers.forEach(unsubscribe => unsubscribe());
}

export function updateUser(userId, data) {
  const db = firebase.firestore();
  return db.collection('users').doc(userId).update(data);
}
