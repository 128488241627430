export const green = '#00C298';
export const opaqueGreen = `${green}25`;
export const orange = '#FF5D00';
const black = 'black';

const theme = {
  global: {
    colors: {
      brand: black,
      'accent-1': green,
      focus: black,
      'brand-contrast': opaqueGreen,
      text: 'black',
    },
    font: 'Inter',
    input: {
      weight: 400,
    },
  },
  anchor: {
    fontWeight: 500,
  },
  button: {
    border: {
      radius: '3px',
    },
    padding: {
      horizontal: '10px',
      vertical: '5px',
    },
    size: {
      small: {
        border: {
          radius: '0.25em',
        },
        pad: {
          horizontal: '8px',
          vertical: '4px',
        },
      },
      large: {
        border: {
          radius: '0.25em',
        },
        pad: {
          horizontal: '12px',
          vertical: '8px',
        },
      },
    },
    default: {
      color: 'text',
      border: undefined,
    },
    primary: {
      background: { color: 'brand' },
      border: undefined,
      color: 'white',
    },
    secondary: {
      color: 'text',
      background: { color: 'light-2' },
    },
    active: {
      background: { color: 'brand' },
      color: 'white',
      secondary: {
        background: 'none',
        border: {
          color: 'brand-contrast',
        },
      },
    },
    disabled: {
      opacity: 0.3,
      secondary: {
        border: { color: 'text-weak' },
      },
    },
    hover: {
      background: { color: 'brand' },
      color: 'white',
      default: {
        background: { color: 'light-2' },
      },
    },
    extend: () => ({ fontWeight: 500 }),
  },
  layer: {
    background: {
      dark: 'dark-1',
      light: 'light-1',
    },
    extend: () => ({
      overflow: 'scroll',
    }),
  },
  accordion: {
    border: undefined,
  },
  formField: {
    content: {
      pad: 'large',
    },
    label: {
      margin: {
        horizontal: 'none',
      },
    },
    help: {
      margin: 'none',
    },
    border: {
      side: 'all',
    },
    round: 'small',
  },
  heading: {
    weight: 700,
    extend: props =>
      !props.margin && {
        margin: '0px',
      },
  },
  menu: {
    icons: {
      color: 'dark-1',
    },
  },
};

export default theme;
